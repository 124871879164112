<template>
  <div class="row q-my-md">
    <div class="col-xs-12 col-md-2 col-lg-2 col-12 tabcustom" v-show="$q.screen.gt.sm">
      <div class="menu-lateral">

        <div :class="tab === 'dados' ? 'categoria ativa' : 'categoria'" @click="tab = 'dados', $emit('tab', { tab: 'dados' })">
          <p>Dados</p>
        </div>

        <div :class="tab === 'verificacoes' ? 'categoria ativa' : 'categoria'" @click="tab = 'verificacoes', $emit('tab', { tab: 'verificacoes' })">
          <p>Verificações</p>
        </div>

        <div :class="tab === 'fotos' ? 'categoria ativa' : 'categoria'" @click="tab = 'fotos', $emit('tab', { tab: 'fotos' })">
          <p>Fotos</p>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-3 col-12 q-my-xl bg-white no-margin" v-show="$q.screen.lt.md">
      <q-tabs v-model="tab" inline-label align="left" style="color: var(--q-color-primary); width: 100%;" class="bg-white">
        <q-tab name="dados" @click="$emit('tab', { tab: 'dados' })" label="Dados" />
        <q-tab name="verificacoes" label="Verificações" @click="$emit('tab', { tab: 'verificacoes' })" />
        <q-tab name="fotos" label="Fotos / Vídeos" @click="$emit('tab', { tab: 'fotos' })" />
      </q-tabs>

      <div class="col-12">
        <hr />
      </div>

    </div>

    <div class="col-xs-12 col-md-10 col-lg-10 col-12 row painel-menu">
      <q-tab-panels style="width: 100%;" v-model="tab" animated>
        <slot></slot>
      </q-tab-panels>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tab: 'dados'
    }
  },
  props: {
    externo: {
      type: Boolean,
      default: false
    }
  }
}
</script>
